import React, { useState } from 'react'
import Price from './price'
import PriceWithVariants from './PriceWithVariants'
import Lightbox from 'react-image-lightbox'
import { Box, Image, Flex, Text } from 'theme-ui'
import { MenuContext } from './MenuContext'
import { FaCamera } from 'react-icons/fa'
// import LinkSelector from '../../Links/LinkSelector'

const MenuItem = ({ item, type, withDollar, hasMenuImages, menuItemIndex }) => {
  const context = React.useContext(MenuContext)

  const { setMenuImageDisplayed, menuImageDisplayed } = context

  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: '',
  })

  const getMenuItemType = () => {
    switch (type) {
      case 'someCase':
      default:
        return defaultType()
    }
  }

  // When copying a menu, for some reason the string URL gets a -copy attached at the end of it. This function removes it.
  const removeImageCopy = (img) =>
    img.includes('copy') ? img.substring(0, img.length - 5) : img

  const imageCopy = item.imageUrl.includes('copy')
    ? removeImageCopy(item.imageUrl)
    : item.imageUrl

  const businessLogo = `${context.businessData.avatar.imageBaseUrl}/${context.businessData.avatar.imagePrefix}`

  const defaultType = () => (
    <Box
      variant={`${context.variantName}.menuItemInnerContainer`}
      className='menuItemInnerContainer'
    >
      {lightBox.isOpen && (
        <Lightbox
          imageCaption={
            <Box sx={{ height: '25px' }}>
              <Text
                as='p'
                variant={`${context.variantName}.menuItemName`}
                className='menuItemName'
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {item.name}
              </Text>
            </Box>
          }
          mainSrc={removeImageCopy(lightBox.mainSrc)}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        />
      )}

      {context.options?.menuImages ? (
        <Box
          sx={{ cursor: `${item.photo_id ? 'pointer' : 'unset'}` }}
          variant={`${context.variantName}.menuItemImageContainer`}
          className='menuItemImageContainer'
          className={`${
            item.photo_id
              ? 'menuItemImageContainer'
              : 'menuItemImgDefaultContainer'
          }`}
          onClick={
            item.photo_id
              ? () =>
                  setLightbox({
                    isOpen: true,
                    mainSrc: item.imageUrl,
                  })
              : () => ''
          }
        >
          <Box
            variant={`${context.variantName}.itemImageFill`}
            className='itemImageFill'
          />

          <Image
            sx={{
              cursor: `${item.photo_id ? 'pointer' : 'unset'}`,
              objectFit: `${item.photo_id ? 'cover' : 'contain'}`,
            }}
            className={`${
              item.photo_id ? 'menuItemImg' : 'menuItemImgDefault'
            }`}
            variant={`${context.variantName}.${
              item.photo_id ? 'menuItemImg' : 'menuItemImgDefault'
            }`}
            src={item.photo_id ? imageCopy : businessLogo}
            alt='menu item'
          />
        </Box>
      ) : (
        ''
      )}
      {item?.extraImages[0] && item?.extraImages && (
        <Box
          variant={`${context.variantName}.secondMenuItemImage`}
          className='secondMenuItemImage'
        >
          <Image src={item.extraImages[0].imageUrl} />
        </Box>
      )}

      <Flex
        variant={`${context.variantName}.menuItemContentContainer`}
        className='menuItemContentContainer'
      >
        {item.variants.length === 1 && item.variants[0].label === '' ? (
          <Flex
            variant={`${context.variantName}.menuItemNamePriceContainer`}
            className='menuItemNamePriceContainer'
          >
            <Text
              variant={`${context.variantName}.menuItemName`}
              className='menuItemName'
            >
              {item.name}{' '}
              {item.photo_id && (
                <FaCamera
                  sx={{
                    marginLeft: '0.5rem',
                    display: ['none', '', '', 'inline'],
                  }}
                  variant={`${context.variantName}.menuItemCamera`}
                  onClick={() => {
                    setMenuImageDisplayed(item.imageUrl)
                  }}
                />
              )}
            </Text>
            <Price withDollar={withDollar} variants={item.variants} toSide />
          </Flex>
        ) : (
          <Box className='menuItemNamePriceContainer'>
            <Text
              variant={`${context.variantName}.menuItemName`}
              className='menuItemName'
            >
              {item.name}{' '}
              {item.photo_id && (
                <FaCamera
                  sx={{
                    marginLeft: '0.5rem',
                    display: ['none', '', '', 'inline'],
                  }}
                  variant={`${context.variantName}.menuItemCamera`}
                  onClick={() => {
                    setMenuImageDisplayed(item.imageUrl)
                  }}
                />
              )}
            </Text>
            <PriceWithVariants
              withDollar={withDollar}
              variants={item.variants}
              toSide
            />
          </Box>
        )}
        {item.desc && (
          <Text
            variant={`${context.variantName}.menuItemDescription`}
            className='menuItemDescription'
          >
            {item.desc}
          </Text>
        )}

        {/* {context?.options?.menuItemLinks && context?.options?.menuItemLinks[menuItemIndex] ? (
          <LinkSelector
            variant={`${context.variantName}`}
            ctaName={context?.options?.menuItemLinkNames[menuItemIndex]}
            ctaLink={context?.options?.menuItemLinks[menuItemIndex]}
            isMultiLocationSite={context?.isMultiLocationSite}
            city={context?.businessData.city}
          />
        ) : (
          ''
        )} */}
      </Flex>
    </Box>
  )

  return (
    <Box
      variant={`${context.variantName}.${
        context.options.menuImages
          ? 'menuItemContainerImgActive'
          : 'menuItemContainer'
      }`}
      className={`${
        context.options.menuImages
          ? 'menuItemContainerImgActive'
          : 'menuItemContainer'
      }`}
    >
      {getMenuItemType()}
    </Box>
  )
}

export default MenuItem
