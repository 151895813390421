import React from 'react'
import splitSectionChildren from '../../helpers/splitSectionChildren'
import MenuCell from './menuCell'
import MenuSectionDropdown from './MenuSectionsDropdown'
import { Box } from 'theme-ui'
import { MenuContext } from './MenuContext'
import shortid from 'shortid'

const Cells = ({ menuData, onCellClick, mode }) => {
  const { childSections } = splitSectionChildren(menuData)
  const context = React.useContext(MenuContext)

  const renderChildSections = () => {
    return childSections.map(({ section, inventory }, idx) => (
      <MenuCell
        key={shortid.generate()}
        section={section}
        inventory={inventory}
        onCellClick={onCellClick}
        numSections={childSections.length}
        mode={mode}
      />
    ))
  }

  return (
    <div>
      <MenuSectionDropdown
        key={shortid.generate()}
        childSections={childSections}
        onCellClick={onCellClick}
      />

      <Box
        variant={`${context.variantName}.cellContainer`}
        className='cellContainer'
      >
        {renderChildSections()}
      </Box>
    </div>
  )
}

export default React.memo(Cells)
