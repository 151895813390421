import React, { useContext } from 'react'
import { Box, Text, Heading, Image } from 'theme-ui'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { MenuContext } from './MenuContext'
import { AiOutlineClose } from 'react-icons/ai'

export default function MenuSlider({ images }) {
  const context = React.useContext(MenuContext)

  const { menuImageDisplayed, setMenuImageDisplayed } = context

  return (
    <Box sx={styles.container}>
      {menuImageDisplayed && (
        <Image sx={styles.lightboxImage} src={menuImageDisplayed} />
      )}
      {menuImageDisplayed && (
        <AiOutlineClose
          sx={styles.closeButton}
          onClick={() => setMenuImageDisplayed(false)}
        />
      )}
      {console.log(images)}

      <Slider {...settings}>
        {images.map((image) => {
          return (
            <>
              <Image sx={styles.image} src={image} alt='menu items' />
            </>
          )
        })}
      </Slider>
    </Box>
  )
}

const styles = {
  container: {
    display: ['none', '', '', 'flex'],
    flexDirection: 'column',
    width: ['100%', '', '', '50%'],
    height: '100vh',
    position: 'fixed',
    left: '0%',
    top: '0%',

    '.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div':
      {
        width: '100%',
        height: 'inherit',
      },
    '.slick-arrow': {
      width: '30px',
      zIndex: '100',
      height: '30px',
      backgroundColor: 'white',
      path: {
        fill: 'primary',
      },
    },
    '.slick-prev': {
      left: '0rem',
    },
    '.slick-next': {
      right: '0rem',
    },
    '.slick-dots': {
      position: 'fixed',
      top: '50%',
      transform: 'translateY(-50%)',
      height: 'fit-content',
      left: '1rem',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      li: {
        display: 'flex',
        width: '30px',
        height: '30px',
        button: {
          width: '30px',
          height: '30px',
          '::before': {
            fontSize: '16px',
            color: 'primary',
            width: '30px',
            height: '30px',
          },
        },
      },
    },
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: '-1',
  },
  lightboxImage: {
    position: 'fixed',
    top: '0rem',
    left: '0rem',
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    zIndex: '500',
  },
  closeButton: {
    position: 'fixed',
    top: 'calc(62px + 1rem)',
    backgroundColor: 'primary',
    left: '1rem',
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    zIndex: '600',
    color: 'white',
    width: '30px',
    height: '30px',
    ':hover': {
      backgroundColor: 'black',
    },
  },
}

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  fade: true,
  speed: 500,
  autoplaySpeed: 4000,
  interval: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}
