import React, { useState } from 'react'
import { MenuContext } from './MenuContext'
import { Box } from 'theme-ui'

export default function SectionDropdownLink({
  onCellClick,
  name,
  section,
  inventory,
}) {
  const context = React.useContext(MenuContext)
  const [arrowActive, toggleArrow] = useState(false)

  return (
    <Box
      variant={`${context.variantName}.dropdownSection`}
      className='dropdownSection'
      key={section.name}
      onMouseOver={() => {
        if (arrowActive) {
        }
        toggleArrow(true)
      }}
      onMouseOut={() => {
        toggleArrow(false)
      }}
      onClick={() => onCellClick({ section, inventory })}>
      <span> {name}</span>
    </Box>
  )
}
