import React from 'react'
import SectionDropdownLink from './SectionDropdownLink'
import { MenuContext } from './MenuContext'
import { Flex, Box } from 'theme-ui'
import shortid from 'shortid'

export default function SectionDropdown({ childSections, onCellClick }) {
  const context = React.useContext(MenuContext)
  return (
    <Flex
      variant={`${context.variantName}.menuSectionsDropDownContainer`}
      className='menuSectionsDropDownContainer'>
      <Box>
        {childSections.map(({ section, inventory }) => {
          return (
            <SectionDropdownLink
              key={shortid.generate()}
              onCellClick={onCellClick}
              name={section.name}
              section={section}
              inventory={inventory}
            />
          )
        })}
      </Box>
    </Flex>
  )
}
