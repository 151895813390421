import React from 'react'
import splitSectionChildren from '../../helpers/splitSectionChildren'
import MenuTab from './menuTab'
import { Box } from 'theme-ui'
import { MenuContext } from './MenuContext'

const Tabs = ({
  menuData,
  onCellClick,
  children,
  activeSection,
  setActiveSection,
  hasNestedTabs,
  displayedCellSection,
  setDisplayedCellSection,
  isTabs,
}) => {
  const { childSections } = splitSectionChildren(menuData)
  const context = React.useContext(MenuContext)

  const renderChildSections = () => {
    if (hasNestedTabs) {
      return activeSection.inventory.map(({ section, inventory }) => {
        return (
          <MenuTab
            section={section}
            inventory={inventory}
            onCellClick={onCellClick}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            displayedCellSection={displayedCellSection}
            hasNestedTabs
            setDisplayedCellSection={setDisplayedCellSection}
          />
        )
      })
    } else {
      return childSections.map(({ section, inventory }) => (
        <MenuTab
          section={section}
          inventory={inventory}
          onCellClick={onCellClick}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          displayedCellSection={displayedCellSection}
          setDisplayedCellSection={setDisplayedCellSection}
          isTabs
        />
      ))
    }
  }

  return (
    <Box variant={`${context.variantName}.tabsRow`} className='tabsRow'>
      {/* {displayedCellSection && renderChildSections()} */}
      {activeSection && renderChildSections()}

      {/* {children} */}
    </Box>
  )
}

export default Tabs
