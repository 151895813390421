import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { MenuContext } from './MenuContext'
import { useLocation } from '@reach/router'
import slugify from '../../helpers/slugifyLower'

const MenuTab = ({
  onCellClick,
  section,
  inventory,
  activeSection,
  hasNestedTabs,
  setActiveSection,
  displayedCellSection,
  setDisplayedCellSection,
  isTabs,
}) => {
  const context = React.useContext(MenuContext)
  let location = useLocation()
  const variantName = `${context.variantName}.${
    activeSection.section?._id === section._id
      ? 'sectionTabActive'
      : 'sectionTab'
  }`

  useEffect(() => {
    console.log(location.hash)
    console.log(`#${slugify(section.name)}`)
    console.log(location.hash === `#${slugify(section.name)}`)
    if (location.hash === `#${slugify(section.name)}`) {
      console.log('INSIDE IF')
      setActiveSection({ section, inventory })
    }
    return () => {}
  }, [])

  return (
    <Box
      id='menuTab'
      // className={`sectionTab has-text-centered ${
      //   activeSection.section?._id === section.id ? 'tab-is-active' : ''
      // } ${displayedCellSection.section === section ? 'tab-is-active' : ''} `}
      variant={variantName}
      onClick={() =>
        isTabs
          ? setActiveSection({ section, inventory })
          : setDisplayedCellSection({
              section,
              inventory,
            })
      }
    >
      {section.name}
    </Box>
  )
}

export default MenuTab
