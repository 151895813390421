import React from 'react'
import { Box, Text, Heading } from 'theme-ui'
import Menu from '../components/Menu/Menu'
export default function menu() {
  const options = {
    removeSections: [''],
    powerListNumber: '1',
    menuImages: true,
    menuLogo: false,
  }

  return (
    <Box sx={styles.container}>
      {/* <h1>Menu Is Pending</h1> */}
      <Menu options={options} poweredListID={'1'} />
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
  },
}
