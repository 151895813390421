import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { Spinner, Flex, Box } from 'theme-ui'
import AllInOnce from './allIn'
import CellsAndAllIn from './cellsAndAllIn'
import TabsView from './tabsView'
import { MenuContext } from './MenuContext'
import filterMenu from '../../helpers/filterMenus'
import { ContextProvider, store } from '../../context/store'
import 'react-image-lightbox/style.css'
import MenuSlider from './MenuSlider'
import LoaderSpinner from '../UI/LoaderSpinner'

// const options ={
//   removeSections:[''],
//   powerListNumber: '1',
//   menuImages: false,
//   menuLogo: false,
// }

export default function Menu({ poweredListID, options }) {
  const { globalState, dispatch } = useContext(store)
  const { businessData } = globalState

  const [menuData, setMenuData] = useState({})
  const [menuImageDisplayed, setMenuImageDisplayed] = useState(false)
  const gonationID = 'bzn-ZwpxdubGQEuQvv1q88omCw'
  const variantName = 'menuV1'

  // Fetch the menu data when the template renders.
  useEffect(() => {
    axios({
      url: `https://data.prod.gonation.com/pl/get?profile_id=${gonationID}&powerlistId=powered-list-${poweredListID}`,
      adapter: jsonpAdapter,
    })
      .then((res) => {
        // filters out any unwanted sections by creating a new array
        const menuList = options?.removeSections
          ? filterMenu(res.data[0].inventory, options.removeSections)
          : res.data

        if (options.removeSections) {
          res.data[0].inventory = menuList
        } // replaces old array with new array
        setMenuData(res.data[0]) // sets menu data to state.
      })
      .catch((error) => {
        console.log(error)
      })

    setTimeout(() => {
      window?.scrollTo(0, 0)
    }, 500)
  }, [gonationID, options, poweredListID])

  const getRenderType = () => {
    switch (menuData.mode) {
      case 'allInOnce':
        return (
          <AllInOnce
            menuData={menuData}
            variantName={variantName}
            mode={menuData.mode}
          />
        )
      case 'cellsThenAllInOnce':
        return (
          <CellsAndAllIn
            menuData={menuData}
            variantName={variantName}
            mode={menuData.mode}
          />
        )
      case 'tabs':
        return (
          <TabsView
            menuData={menuData}
            variantName={variantName}
            mode={menuData.mode}
          />
        )
      case 'cellsThenTabs':
        return (
          <CellsAndAllIn
            mode={menuData.mode}
            menuData={menuData}
            isCellsAndTabs
            variantName={variantName}
          />
        )
      default:
        return (
          <h1>Menu Could Not Be Loaded At This Time, please Try Again Later</h1>
        )
    }
  }

  const renderSlideImages = () => {
    return menuData.inventory.map((section) => section.section.imageUrl)
  }

  return (
    // react context added instead of prop drilling through menu components
    <MenuContext.Provider
      value={{
        businessData,
        variantName,
        options,
        setMenuImageDisplayed,
        menuImageDisplayed,
      }}
    >
      {menuData?.section ? (
        <Box sx={customStyles.container}>
          <MenuSlider images={renderSlideImages()} />
          <Box
            className='innerMenuContainer'
            sx={customStyles.innerMenuContainer}
            id='innerMenuContainer'
          >
            {getRenderType()}
          </Box>
        </Box>
      ) : (
        <LoaderSpinner />
      )}
    </MenuContext.Provider>
  )
}

const customStyles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', '', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  innerMenuContainer: {
    width: ['100%', '', '', '50%'],
    marginLeft: ['', '', '', '50%'],
    position: 'relative',
    paddingTop: ['125px', '', '', '100px'],
  },
}
