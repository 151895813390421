import React, { useState } from 'react'
import { Flex, Button } from 'theme-ui'
import SectionDropdown from './SectionDropdown'
import { MenuContext } from './MenuContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function MenuSectionsDropdown({ childSections, onCellClick }) {
  const [dropdownOpen, toggleDropdown] = useState(false)
  const context = React.useContext(MenuContext)

  return (
    <Flex
      variant={`${context.variantName}.dropdownContainer`}
      className='dropdownContainer'
      onMouseLeave={() => toggleDropdown(false)}>
      <Button
        variant={`${context.variantName}.menuDropdownBtn`}
        className='menuDropdownBtn'
        onMouseEnter={() => toggleDropdown(true)}
        onClick={() => toggleDropdown(true)}>
        Our Menu &nbsp; <FontAwesomeIcon icon={faCaretDown} />
      </Button>

      {dropdownOpen ? (
        <SectionDropdown
          childSections={childSections}
          onCellClick={onCellClick}
        />
      ) : null}
    </Flex>
  )
}
