import React from 'react'
import shortid from 'shortid'
import { Box, Flex } from '@theme-ui/components'
import { MenuContext } from './MenuContext'
const Price = ({ variants, withDollar, toSide }) => {
  const context = React.useContext(MenuContext)

  const renderPrices = () =>
    variants
      // .filter(el => {
      //   return el.label !== '' && el.price !== ''
      // })
      .map((variant, index) => (
        <Box
          variant={`${context.variantName}.variantsContainer`}
          className='variantsContainer'
          key={shortid.generate()}>
          {variant.labelTitle ? (
            <Flex
              variant={`${context.variantName}.labelTitle`}
              className='labelTitle'>
              {variant.labelTitle}
            </Flex>
          ) : (
            ''
          )}

          <Flex
            variant={`${context.variantName}.${
              index === 0 && !variant.label
                ? 'variantContainerFloatTopRight'
                : 'variantContainer'
            }`}
            className={`${
              index === 0 && !variant.label
                ? 'variantContainerFloatTopRight'
                : 'variantContainer'
            }`}>
            {variant.label && (
              <Box
                variant={`${context.variantName}.menuItemPriceLabel`}
                className='menuItemPriceLabel'>
                {variant.label}
              </Box>
            )}
            {/* {!variant.label && ' - '} */}
            <Box
              variant={`${context.variantName}.menuItemPriceVariants`}
              className='menuItemPriceVariants'>
              {withDollar ? '$' : ''}
              {variant.price}
            </Box>
          </Flex>
        </Box>
      ))

  return <Box className='itemVariantsContainer' variant={`${context.variantName}.itemVariantsContainer`}>{renderPrices()}</Box>
}

export default Price
