import React, { useState } from 'react'
import AllIn from './allIn'
import Tabs from './tabs'
// import slugify from 'slugify'

const TabsView = ({ menuData, setModalActive }) => {
  const [activeSection, setActiveSection] = useState({
    section: menuData?.inventory?.[0]?.section,
    inventory: menuData?.inventory?.[0]?.inventory,
  })

  const onCellClick = (selection, inventory) => {
    setActiveSection({
      section: selection,
      inventory,
    })

    setTimeout(() => {
      try {
        const element = document.querySelector(`#menuTab`)

        if (element) {
          const offset = 250
          const bodyRect = document.body.getBoundingClientRect().top
          const elementRect = element.getBoundingClientRect().top
          const elementPosition = elementRect - bodyRect
          const offsetPosition = elementPosition - offset

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          })
        }
      } catch (error) {
        console.log('could not scroll')
      }
    }, 500)
  }

  const childrenWithProps = () => (
    <AllIn menuData={activeSection} setModalActive={setModalActive} />
  )

  return (
    <div>
      <Tabs
        menuData={menuData}
        onCellClick={onCellClick}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isTabs
      />
      {childrenWithProps()}
    </div>
  )
}

export default TabsView
